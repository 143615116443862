export const mutuals = [
    {
        name: "Quant Multi Asset Fund Direct-Growth",
        CurrentValue: "Rs. 11.53 L",
        MinInvest: "₹1000",
        Return: "+ 26.47 % p.a.",
        AUM: "Rs 843 Crs",


    },
    {
        name: "ICICI Prudential Equity & Debt Fund",
        CurrentValue: "Rs. 10.21 L",
        MinInvest: "₹100",
        Return: "+ 21.43 % p.a.",
        AUM: "Rs 23,711 Crs",


    },
    {
        name: "ICICI Prudential Multi Asset Fund",
        CurrentValue: "Rs. 10.18 L",
        MinInvest: "₹100",
        Return: "+ 21.32 % p.a.",
        AUM: "Rs 19974 Crs",


    },
    {
        name: "Edelweiss Agressive Hybrid Fund",
        CurrentValue: "Rs. 9.46 L",
        MinInvest: "₹500",
        Return: "+ 18.27 % p.a.",
        AUM: "Rs 669 Crs",


    },
    {
        name: "Baroda BNP Paribas Aggresive Hybrid Fund",
        CurrentValue: "Rs. 9.03 L",
        MinInvest: "₹500",
        Return: "+ 16.38 % p.a.",
        AUM: "Rs 833 Crs",


    },
    {
        name: "Mirae Asser Hybrid Equity Fund",
        CurrentValue: "Rs. 8.83 L",
        MinInvest: "₹1000",
        Return: "+ 15.48 % p.a.",
        AUM: "Rs 7573 Crs",


    },

]